import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0499ff3c = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _1010b780 = () => interopDefault(import('../pages/m/404.vue' /* webpackChunkName: "pages/m/404" */))
const _feabb308 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b46e2bd0 = () => interopDefault(import('../pages/m/_lang/index.vue' /* webpackChunkName: "pages/m/_lang/index" */))
const _506be040 = () => interopDefault(import('../pages/m/_lang/event.vue' /* webpackChunkName: "pages/m/_lang/event" */))
const _33032e63 = () => interopDefault(import('../pages/m/_lang/event/index.vue' /* webpackChunkName: "pages/m/_lang/event/index" */))
const _553f6d8b = () => interopDefault(import('../pages/m/_lang/event/_id.vue' /* webpackChunkName: "pages/m/_lang/event/_id" */))
const _1c57b5bc = () => interopDefault(import('../pages/m/_lang/map.vue' /* webpackChunkName: "pages/m/_lang/map" */))
const _b8766bb6 = () => interopDefault(import('../pages/m/_lang/map/index.vue' /* webpackChunkName: "pages/m/_lang/map/index" */))
const _f44ddc66 = () => interopDefault(import('../pages/m/_lang/map/_id.vue' /* webpackChunkName: "pages/m/_lang/map/_id" */))
const _557f582a = () => interopDefault(import('../pages/m/_lang/media.vue' /* webpackChunkName: "pages/m/_lang/media" */))
const _3fa0d1a6 = () => interopDefault(import('../pages/m/_lang/media/index.vue' /* webpackChunkName: "pages/m/_lang/media/index" */))
const _1e57c1f4 = () => interopDefault(import('../pages/m/_lang/media/imageList.vue' /* webpackChunkName: "pages/m/_lang/media/imageList" */))
const _85ffe5d8 = () => interopDefault(import('../pages/m/_lang/media/videoList.vue' /* webpackChunkName: "pages/m/_lang/media/videoList" */))
const _d86fb306 = () => interopDefault(import('../pages/m/_lang/news.vue' /* webpackChunkName: "pages/m/_lang/news" */))
const _9a69c980 = () => interopDefault(import('../pages/m/_lang/news/index.vue' /* webpackChunkName: "pages/m/_lang/news/index" */))
const _20e6eda8 = () => interopDefault(import('../pages/m/_lang/news/_id.vue' /* webpackChunkName: "pages/m/_lang/news/_id" */))
const _1e70c5e1 = () => interopDefault(import('../pages/m/_lang/notices.vue' /* webpackChunkName: "pages/m/_lang/notices" */))
const _482f9fa4 = () => interopDefault(import('../pages/m/_lang/notices/index.vue' /* webpackChunkName: "pages/m/_lang/notices/index" */))
const _2a053afa = () => interopDefault(import('../pages/m/_lang/roles.vue' /* webpackChunkName: "pages/m/_lang/roles" */))
const _4188bac6 = () => interopDefault(import('../pages/m/_lang/roles/index.vue' /* webpackChunkName: "pages/m/_lang/roles/index" */))
const _2372b6ae = () => interopDefault(import('../pages/m/_lang/roles/_id.vue' /* webpackChunkName: "pages/m/_lang/roles/_id" */))
const _a9f6fba4 = () => interopDefault(import('../pages/m/_lang/others/Agreement_AppleStore.vue' /* webpackChunkName: "pages/m/_lang/others/Agreement_AppleStore" */))
const _47d12ad8 = () => interopDefault(import('../pages/m/_lang/others/Agreement_GooglePlay.vue' /* webpackChunkName: "pages/m/_lang/others/Agreement_GooglePlay" */))
const _4c30f3c4 = () => interopDefault(import('../pages/m/_lang/others/contact.vue' /* webpackChunkName: "pages/m/_lang/others/contact" */))
const _529aee17 = () => interopDefault(import('../pages/m/_lang/others/instructions.vue' /* webpackChunkName: "pages/m/_lang/others/instructions" */))
const _5d8daab8 = () => interopDefault(import('../pages/m/_lang/others/policy.vue' /* webpackChunkName: "pages/m/_lang/others/policy" */))
const _6486d633 = () => interopDefault(import('../pages/m/_lang/others/service.vue' /* webpackChunkName: "pages/m/_lang/others/service" */))
const _097f043a = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _3b802a02 = () => interopDefault(import('../pages/_lang/event.vue' /* webpackChunkName: "pages/_lang/event" */))
const _425cb1f6 = () => interopDefault(import('../pages/_lang/event/index.vue' /* webpackChunkName: "pages/_lang/event/index" */))
const _5e37a6ad = () => interopDefault(import('../pages/_lang/event/_id.vue' /* webpackChunkName: "pages/_lang/event/_id" */))
const _432e5fc4 = () => interopDefault(import('../pages/_lang/map.vue' /* webpackChunkName: "pages/_lang/map" */))
const _a685f972 = () => interopDefault(import('../pages/_lang/map/index.vue' /* webpackChunkName: "pages/_lang/map/index" */))
const _571da922 = () => interopDefault(import('../pages/_lang/map/_id.vue' /* webpackChunkName: "pages/_lang/map/_id" */))
const _8d951b68 = () => interopDefault(import('../pages/_lang/media.vue' /* webpackChunkName: "pages/_lang/media" */))
const _e803e062 = () => interopDefault(import('../pages/_lang/media/index.vue' /* webpackChunkName: "pages/_lang/media/index" */))
const _51d5ccd4 = () => interopDefault(import('../pages/_lang/media/imageList.vue' /* webpackChunkName: "pages/_lang/media/imageList" */))
const _75bd64b6 = () => interopDefault(import('../pages/_lang/media/videoList.vue' /* webpackChunkName: "pages/_lang/media/videoList" */))
const _6db5401b = () => interopDefault(import('../pages/_lang/news.vue' /* webpackChunkName: "pages/_lang/news" */))
const _6e4bf344 = () => interopDefault(import('../pages/_lang/news/index.vue' /* webpackChunkName: "pages/_lang/news/index" */))
const _b55bef74 = () => interopDefault(import('../pages/_lang/news/_id.vue' /* webpackChunkName: "pages/_lang/news/_id" */))
const _3a4277c6 = () => interopDefault(import('../pages/_lang/notices/index.vue' /* webpackChunkName: "pages/_lang/notices/index" */))
const _4eb37ca5 = () => interopDefault(import('../pages/_lang/roles.vue' /* webpackChunkName: "pages/_lang/roles" */))
const _25519930 = () => interopDefault(import('../pages/_lang/roles/index.vue' /* webpackChunkName: "pages/_lang/roles/index" */))
const _2c6aefd0 = () => interopDefault(import('../pages/_lang/roles/_id.vue' /* webpackChunkName: "pages/_lang/roles/_id" */))
const _b33d1260 = () => interopDefault(import('../pages/_lang/others/Agreement_AppleStore.vue' /* webpackChunkName: "pages/_lang/others/Agreement_AppleStore" */))
const _51174194 = () => interopDefault(import('../pages/_lang/others/Agreement_GooglePlay.vue' /* webpackChunkName: "pages/_lang/others/Agreement_GooglePlay" */))
const _2a2fb23c = () => interopDefault(import('../pages/_lang/others/contact.vue' /* webpackChunkName: "pages/_lang/others/contact" */))
const _37bfa4b9 = () => interopDefault(import('../pages/_lang/others/instructions.vue' /* webpackChunkName: "pages/_lang/others/instructions" */))
const _7967fa74 = () => interopDefault(import('../pages/_lang/others/policy.vue' /* webpackChunkName: "pages/_lang/others/policy" */))
const _9661fb5e = () => interopDefault(import('../pages/_lang/others/service.vue' /* webpackChunkName: "pages/_lang/others/service" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0499ff3c,
    name: "404"
  }, {
    path: "/m/404",
    component: _1010b780,
    name: "m-404"
  }, {
    path: "/",
    component: _feabb308,
    name: "index"
  }, {
    path: "/m/:lang",
    component: _b46e2bd0,
    name: "m-lang"
  }, {
    path: "/m/:lang?/event",
    component: _506be040,
    children: [{
      path: "",
      component: _33032e63,
      name: "m-lang-event"
    }, {
      path: ":id",
      component: _553f6d8b,
      name: "m-lang-event-id"
    }]
  }, {
    path: "/m/:lang?/map",
    component: _1c57b5bc,
    children: [{
      path: "",
      component: _b8766bb6,
      name: "m-lang-map"
    }, {
      path: ":id",
      component: _f44ddc66,
      name: "m-lang-map-id"
    }]
  }, {
    path: "/m/:lang?/media",
    component: _557f582a,
    children: [{
      path: "",
      component: _3fa0d1a6,
      name: "m-lang-media"
    }, {
      path: "imageList",
      component: _1e57c1f4,
      name: "m-lang-media-imageList"
    }, {
      path: "videoList",
      component: _85ffe5d8,
      name: "m-lang-media-videoList"
    }]
  }, {
    path: "/m/:lang?/news",
    component: _d86fb306,
    children: [{
      path: "",
      component: _9a69c980,
      name: "m-lang-news"
    }, {
      path: ":id",
      component: _20e6eda8,
      name: "m-lang-news-id"
    }]
  }, {
    path: "/m/:lang?/notices",
    component: _1e70c5e1,
    children: [{
      path: "",
      component: _482f9fa4,
      name: "m-lang-notices"
    }]
  }, {
    path: "/m/:lang?/roles",
    component: _2a053afa,
    children: [{
      path: "",
      component: _4188bac6,
      name: "m-lang-roles"
    }, {
      path: ":id",
      component: _2372b6ae,
      name: "m-lang-roles-id"
    }]
  }, {
    path: "/m/:lang?/others/Agreement_AppleStore",
    component: _a9f6fba4,
    name: "m-lang-others-Agreement_AppleStore"
  }, {
    path: "/m/:lang?/others/Agreement_GooglePlay",
    component: _47d12ad8,
    name: "m-lang-others-Agreement_GooglePlay"
  }, {
    path: "/m/:lang?/others/contact",
    component: _4c30f3c4,
    name: "m-lang-others-contact"
  }, {
    path: "/m/:lang?/others/instructions",
    component: _529aee17,
    name: "m-lang-others-instructions"
  }, {
    path: "/m/:lang?/others/policy",
    component: _5d8daab8,
    name: "m-lang-others-policy"
  }, {
    path: "/m/:lang?/others/service",
    component: _6486d633,
    name: "m-lang-others-service"
  }, {
    path: "/:lang",
    component: _097f043a,
    name: "lang"
  }, {
    path: "/:lang/event",
    component: _3b802a02,
    children: [{
      path: "",
      component: _425cb1f6,
      name: "lang-event"
    }, {
      path: ":id",
      component: _5e37a6ad,
      name: "lang-event-id"
    }]
  }, {
    path: "/:lang/map",
    component: _432e5fc4,
    children: [{
      path: "",
      component: _a685f972,
      name: "lang-map"
    }, {
      path: ":id",
      component: _571da922,
      name: "lang-map-id"
    }]
  }, {
    path: "/:lang/media",
    component: _8d951b68,
    children: [{
      path: "",
      component: _e803e062,
      name: "lang-media"
    }, {
      path: "imageList",
      component: _51d5ccd4,
      name: "lang-media-imageList"
    }, {
      path: "videoList",
      component: _75bd64b6,
      name: "lang-media-videoList"
    }]
  }, {
    path: "/:lang/news",
    component: _6db5401b,
    children: [{
      path: "",
      component: _6e4bf344,
      name: "lang-news"
    }, {
      path: ":id",
      component: _b55bef74,
      name: "lang-news-id"
    }]
  }, {
    path: "/:lang/notices",
    component: _3a4277c6,
    name: "lang-notices"
  }, {
    path: "/:lang/roles",
    component: _4eb37ca5,
    children: [{
      path: "",
      component: _25519930,
      name: "lang-roles"
    }, {
      path: ":id",
      component: _2c6aefd0,
      name: "lang-roles-id"
    }]
  }, {
    path: "/:lang/others/Agreement_AppleStore",
    component: _b33d1260,
    name: "lang-others-Agreement_AppleStore"
  }, {
    path: "/:lang/others/Agreement_GooglePlay",
    component: _51174194,
    name: "lang-others-Agreement_GooglePlay"
  }, {
    path: "/:lang/others/contact",
    component: _2a2fb23c,
    name: "lang-others-contact"
  }, {
    path: "/:lang/others/instructions",
    component: _37bfa4b9,
    name: "lang-others-instructions"
  }, {
    path: "/:lang/others/policy",
    component: _7967fa74,
    name: "lang-others-policy"
  }, {
    path: "/:lang/others/service",
    component: _9661fb5e,
    name: "lang-others-service"
  }, {
    path: "*",
    component: _0499ff3c
  }, {
    path: "/m/*",
    component: _1010b780
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
